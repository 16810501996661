import Vue from 'vue'
import VueRouter from 'vue-router'
import login from "@/views/login.vue";
import register from "@/views/register.vue";
import home from "@/views/home.vue";
import pathArr from "@/router/pathArr";
import homeConfiguration from "@/components/homeConfiguration";
import addClass from "@/components/addClass.vue";
import tercherInfo from "@/components/tercherInfo.vue";
import addUser from "@/components/addUser.vue";
import daySubscribe from "@/components/daySubscribe.vue";
import indexhome from "@/components/indexhome.vue";
import cyimage from "@/components/cyimage.vue";

Vue.use(VueRouter)

const routes = [
  {path: '/', redirect:'/login'},
  {
    path: '/login',
    name: 'login',
    component: login
  },
  {
    path: '/register',
    name: 'register',
    component: register
  },
  {
    path: "/home",
    name: 'home',
    component: home,
    children:[
      {path:'/homeConfiguration',component:homeConfiguration},
      {path:'/addClass',component:addClass},
      {path:'/tercherInfo',component:tercherInfo},
      {path:'/addUser',component:addUser},
      {path:'/daySubscribe',component:daySubscribe},
      {path:'/indexhome',component:indexhome},
      {path:'/cyimage',component:cyimage}
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach(function(to, from, next) {
  if (pathArr.indexOf(to.path) !== -1) {
    const token = localStorage.getItem('token')
    if (token) {
      next()
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
