<template>
<div>
  <div class="containers use-table">
    <el-row>
      <el-col :span="24">
        <el-table
            height="60vh"
            :data="tableData"
            style="width: 100%;font-size: 14px">
          <el-table-column
              prop="typestr"
              label="配置项"
              width="180">
          </el-table-column>
          <el-table-column
              label="配置值"
              width="180">
            <template slot-scope="scope">
              <div v-if="scope.row.typeofval === 'link'">
                <el-image
                    fit="cover"
                    style="width: 100px; height: 100px"
                    :src="scope.row.val"
                    :preview-src-list="srcList">
                </el-image>
              </div>
              <span v-else>{{ scope.row.val }}</span>
            </template>
          </el-table-column>
          <el-table-column
              prop="createuser"
              label="创建人">
          </el-table-column>
          <el-table-column
              prop="createtime"
              label="创建时间">
          </el-table-column>
          <el-table-column
              prop="updateuser"
              label="修改人">
          </el-table-column>
          <el-table-column
              prop="updatetime"
              label="修改时间">
          </el-table-column>
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button
                  size="mini"
                  type="danger"
                  @click="handledept(scope.row)">修改
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-col>
    </el-row>
  </div>
  <div>
    <el-dialog :title="dialogTitle" :visible.sync="dialogFormVisible" width="600px">
      <el-form :model="dialogFrom">
        <el-form-item label="配置值" label-width="100px" v-if="!showpz">
          <el-input v-model="dialogFrom.val" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="上传图片" label-width="100px" v-if="showpz">
          <el-upload
              class="avatar-uploader"
              action
              :http-request="uploadFiles"
              :show-file-list="false">
            <img v-if="imageUrl" :src="imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogFormVisible = false">取 消</el-button>
        <el-button type="primary" @click="saveAllocation">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</div>
</template>

<script>
import { uploadFile } from '@/utils/request';
export default {
  name: "homeConfiguration",
  data() {
    return {
      tableData:[],
      srcList: [],
      dialogFrom:{},
      dialogTitle:'',
      dialogTableVisible: false,
      dialogFormVisible: false,
      imageUrl:'',
      showpz:false
    };
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      this.$request.get('/swerAdmin/allocation').then(res =>{
        this.tableData = res.data
        this.srcList = res.data.filter(item => item.typeofval === 'link').map(item => item.val);
      })
    },
    handledept(row){
      this.imageUrl = ""
      this.dialogFrom = {}
      this.dialogTitle = row.typestr
      this.dialogFrom.id = row.id
      this.dialogFrom.typeofval = row.typeofval
      if (row.typeofval === 'link') {
        this.dialogTableVisible = true
        this.dialogFormVisible = true
        this.showpz = true
      } else {
        this.dialogTableVisible = true
        this.dialogFormVisible = true
        this.showpz = false
      }
    },
    uploadFiles(param){
      const isJPG = param.file.type === 'image/jpeg';
      const isLt2M = param.file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
        return
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '正在上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      uploadFile(param.file).then(res => {
        this.imageUrl = res.data.data
        this.dialogFrom.val = res.data.data
        this.$message.success('上传成功')
        loading.close();
      })
    },
    saveAllocation(){
      if (this.dialogFrom.val) {
        this.dialogFormVisible = false
        this.$request.post('/swerAdmin/uplodeAllocation',this.dialogFrom).then(res => {
          this.$message.success(res.message)
          this.init()
        })
      } else {
        this.$message.error('配置值不能为空')
      }
    }
  }
}
</script>
<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>