<template>
<div class="container">
  <div class="form">
    <h1>注册</h1>
    <form>
      <label>Full Name</label>
      <input type="text" id="fullname"  required v-model="user.name">
      <label>Username</label>
      <input type="text" id="username" required v-model="user.username">
      <label >Password</label>
      <input type="password" id="password" required v-model="user.password">
      <input type="button" value="注册" @click="req" style="
        background-color: #008CBA;
        color: #fff;
        border: none;
        border-radius: 5px;
        padding: 10px;
        font-size: 16px;
        cursor: pointer;">
    </form>
  </div>
  <div class="zc"><router-link to="/login">去登录</router-link></div>
</div>
</template>

<script>
import request from "@/utils/request";

export default {
  name: "REgister",
  data(){
    return{
      user:{
          name:'',
          username:'',
          password:''
      }
    }
  },
  methods:{
    req() {
      if (this.user.name === '' || this.user.username === '' || this.user.password === '') {
        this.$message.error("请检查用户名、账号、密码是否输入")
      }else {
        request.post("/user/register", this.user).then(res => {
          if (res.flag) {
            this.$message.success(res.msg)
          }else {
            this.$message.error(res.msg)
          }
        })
      }
    }
  }
}
</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  height: 100vh;
  transform: translateX(10px);
}

.form {
  background-color: #fff;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
  margin: 0 20px;
  width: 400px;
}

h1 {
  font-size: 28px;
  margin-bottom: 20px;
  text-align: center;
}

form {
  display: flex;
  flex-direction: column;
}

label {
  font-size: 16px;
  margin-bottom: 10px;
}

input[type="text"],
input[type="email"],
input[type="password"] {
  font-size: 16px;
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
}

input[type="submit"] {
  background-color: #008CBA;
  color: #fff;
  border: none;
  border-radius: 5px;
  padding: 10px;
  font-size: 16px;
  cursor: pointer;
}

input[type="submit"]:hover {
  background-color: #006b9f;
}
.zc {
  transform: translate(-276px ,210px);
}
.zc a {
  text-decoration: none;
  color: #3e8bb5;
}
</style>
