<template>
  <div>
    <div class="container padding_b_0">
      <div class="dflex_wrap">
        <el-button type="primary" class=" margin_b_20 margin_r_40" icon="el-icon-circle-plus-outline" @click="addClass">添加图片</el-button>
      </div>
    </div>
    <div class="containers use-table" >
      <el-table
          v-loading="loading"
          height="70vh"
          :data="tableData"
          style="width: 100%;font-size: 14px">
        <el-table-column
            prop="imageName"
            label="图片名"
            >
        </el-table-column>
        <el-table-column
            label="图片"
           >
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 50px; height: 50px"
                  :src="scope.row.imageUrl"
                  :preview-src-list="srcList"
                  :z-index="scope.index"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            label="操作"
            >
          <template slot-scope="scope">
            <el-popconfirm
                title="删除后不可撤销，确认删除吗？"
                @confirm="handleClick(scope.row)"
                icon="el-icon-info"
                icon-color="red"
            >
              <el-button slot="reference"  type="text" size="small" style="margin-left: 20px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="initFrom.page"
          :page-sizes="[10, 50, 100]"
          :page-size="initFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!--    添加课程弹窗-->
    <div>
      <el-dialog title="添加图片" :visible.sync="dialogFormVisible" width="70%">
        <el-form :model="dialogFrom" :rules="rules" ref="dialogFrom">
          <el-row>
            <el-col :span="12">
              <el-form-item label="图片名：" label-width="100px" prop="imageName">
                <div style="width: 300px;">
                  <el-input v-model="dialogFrom.imageName" autocomplete="off"></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="图片：" label-width="100px" prop="imageUrl">
                <div style="width:179px">
                  <el-upload
                      class="avatar-uploader"
                      action
                      :http-request="uploadFiles"
                      :show-file-list="false">
                    <img v-if="imageUrl" :src="imageUrl" class="avatar">
                    <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                  </el-upload>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveAllocation('dialogFrom')">确 定</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import {uploadFile} from "@/utils/request";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: 'cyimage',
  data() {
    return {
      initFrom: {
        page: 1,
        pageSize: 10,
        name: '',
      },
      total:null,
      tableData: [],
      srcList: [],
      loading:false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogFrom:{},
      imageUrl:'',
      rules: {
        imageName: [{ required: true, message: '请输入图片名', trigger: ['blur', 'change'] }],
        imageUrl: [{ required: true, message: '请上传图片', trigger: ['blur', 'change'] }],
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$request.post('/swerAdmin/getimageList', this.initFrom).then(res => {
        this.tableData = res.data[0]
        this.srcList = res.data[0].map(item => item.imageUrl)
        this.total = parseInt(res.data[1])
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.page = val
      this.init()
    },
    addClass(){
      this.dialogFrom = {}
      this.dialogTableVisible = true
      this.dialogFormVisible = true
    },
    saveAllocation(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在提交中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$request.post('/swerAdmin/addImageList',this.dialogFrom).then(res =>{
            loading.close();
            this.dialogTableVisible = false
            this.dialogFormVisible = false
            this.$message.success(res.message)
            this.init()
          })
        } else {
          return false;
        }
      });
    },
    handleClick(row){
      const loading = this.$loading({
        lock: true,
        text: '正在删除中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$request.get("/swerAdmin/deletUseImage?id=" + row.id).then(res => {
        loading.close();
        this.$message.success(res.message)
        this.init()
      })
    },
    uploadFiles(param){
      const isJPG = param.file.type === 'image/jpeg';
      const isLt2M = param.file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
        return
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '正在上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      uploadFile(param.file).then(res => {
        this.imageUrl = res.data.data
        this.dialogFrom.imageUrl = res.data.data
        this.$message.success('上传成功')
        loading.close();
      })
    },
  }
}
</script>
<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>