<template>
  <div>
    <div class="container padding_b_0">
      <div class="dflex_wrap">
        <div class="dflex_vertical_c margin_r_40 margin_b_20">
          <div class="search_name">课程开始日期：</div>
          <el-date-picker
              class="search_input"
              v-model="initFrom.date"
              :clearable="false"
              type="date"
              placeholder="课程开始日期">
          </el-date-picker>
        </div>
        <el-button  class=" margin_b_20 margin_r_40" type="primary" icon="el-icon-search" @click="serchclass">查询</el-button>
      </div>
    </div>
    <div class="containers use-table">
      <el-table
          v-loading="loading"
          height="60vh"
          :data="tableData"
          style="width: 100%;font-size: 14px">
        <el-table-column
            prop="id"
            label="课程编号"
            width="200">
        </el-table-column>
        <el-table-column
            prop="title"
            label="标题"
            width="200">
        </el-table-column>
        <el-table-column
            prop="classTeacherName"
            label="课程老师"
            width="130">
        </el-table-column>
        <el-table-column
            prop="studentName"
            label="已预约学生"
            >
        </el-table-column>
        <el-table-column
            prop="starclasstime"
            label="课程开始时间"
            width="150">
        </el-table-column>
        <el-table-column
            prop="endclasstime"
            label="课程结束时间"
            width="150">
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="initFrom.page"
          :page-sizes="[10, 50, 100]"
          :page-size="initFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
export default {
  name: 'daySubscribe',
  data() {
    return {
      initFrom: {
        page: 1,
        pageSize: 10,
        date: null,
      },
      total:null,
      tableData: [],
      loading:false,
    }
  },
  created() {
    this.initFrom.date = this.$moment().format('YYYY-MM-DD')
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$request.post('/swerAdmin/getSubscribe', this.initFrom).then(res => {
        this.tableData = res.data[0]
        console.log(this.tableData)
        this.total = parseInt(res.data[1])
        this.loading = false
        console.log(res)
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.page = val
      this.init()
    },
    serchclass(){
      this.initFrom.date = this.$moment(this.initFrom.date).format('YYYY-MM-DD')
      this.init()
    },
  }
}
</script>
<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>