<template>
  <div>
    <div class="container padding_b_0">
      <div class="dflex_wrap">
        <div class="dflex_vertical_c margin_r_40 margin_b_20">
          <div class="search_input">老师名称：</div>
          <el-input class="search_input" v-model="classTeacherName" placeholder="请输入" autocomplete="off"></el-input>
        </div>
        <el-button class=" margin_b_20 margin_r_40" type="primary" icon="el-icon-search" @click="serchclass">查询</el-button>
        <el-button type="success" class=" margin_b_20 margin_r_40" icon="el-icon-circle-plus-outline" @click="addClass">添加老师</el-button>
      </div>
    </div>
    <div class="containers use-table">
      <el-table
          v-loading="loading"
          height="60vh"
          :data="tableData"
          style="width: 100%;font-size: 14px">
        <el-table-column
            prop="classTeacherName"
            label="老师名称"
            width="130">
        </el-table-column>
        <el-table-column
            prop="classTeacherPicture"
            label="老师照片"
            width="130">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.classTeacherPicture"
                  :preview-src-list="srcList"
                  :z-index="scope.index"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="phone"
            label="电话"
            width="130">
        </el-table-column>
        <el-table-column
            prop="createUser"
            label="创建人"
            width="130">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="150">
        </el-table-column>
        <el-table-column
            prop="updateUser"
            label="修改人"
            width="130">
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="修改时间"
            width="160">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="xg(scope.row)">修改</el-button>
            <el-popconfirm
                style="margin-left: 20px"
                title="删除后不可撤销，确认删除吗？"
                @confirm="handleClick(scope.row)"
                icon="el-icon-info"
                icon-color="red"
            >
              <el-button slot="reference"  type="text" size="small">删除</el-button>
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
    </div>
    <!--    添加老师弹窗-->
    <div>
      <el-dialog title="添加老师" :visible.sync="dialogFormVisible" width="70%">
        <el-form :model="dialogFrom" :rules="rules" ref="dialogFrom">
          <el-row>
            <el-col :span="12">
              <el-form-item label="老师名称：" label-width="100px" prop="classTeacherName">
                <div style="width: 300px;">
                  <el-input v-model="dialogFrom.classTeacherName" autocomplete="off"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="老师电话：" label-width="100px" prop="phone">
                <div style="width: 300px;">
                  <el-input v-model="dialogFrom.phone" autocomplete="off"></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="老师照片：" label-width="100px" prop="detailImgUrl">
            <div style="width:179px">
              <el-upload
                  class="avatar-uploader"
                  action
                  :http-request="uploadFiles"
                  :show-file-list="false">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveAllocation('dialogFrom')" v-if="showadd">确 定</el-button>
            <el-button type="primary" @click="uplodeAllocation('dialogFrom')" v-if="!showadd">修 改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { uploadFile } from '@/utils/request';
export default {
  name: 'tercherInfo',
  data() {
    var checkPhone = (rule, value, callback) => { // 手机号验证
      if (!value) {
        return callback(new Error('手机号不能为空'));
      } else {
        const reg = /^\d{11}$/
        if (reg.test(value)) {
          callback();
        } else {
          return callback(new Error('请输入正确的手机号'));
        }
      }
    };
    return {
      classTeacherName: null,
      showadd:true,
      teacherList:[],
      total:null,
      tableData: [],
      srcList: [],
      loading:false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogFrom:{},
      imageUrl:'',
      classTeacherUserId:'',
      rules: {
        classTeacherName: [{ required: true, message: '请输入老师名称', trigger: ['blur', 'change'] }],
        phone: [
          { required: true, message: '请输入老师电话', trigger: ['blur', 'change'] },
          { validator: checkPhone, trigger: 'blur' }
        ],
        classTeacherPicture: [{ required: true, message: '请上传老师照片', trigger: ['blur', 'change'] }]
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$request.get('/swerAdmin/getTercher?classTeacherName='+this.classTeacherName).then(res => {
        this.tableData = res.data
        this.srcList = res.data.map(item => item.classTeacherPicture)
        this.loading = false
      })
    },
    serchclass(){
      this.init()
    },
    addClass(){
      this.dialogFrom = {}
      this.imageUrl = ''
      this.showadd = true
      this.dialogTableVisible = true
      this.dialogFormVisible = true
    },
    uploadFiles(param){
      const isJPG = param.file.type === 'image/jpeg';
      const isLt2M = param.file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
        return
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '正在上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      uploadFile(param.file).then(res => {
        this.imageUrl = res.data.data
        this.$message.success('上传成功')
        loading.close();
      })
    },
    xg(row){
      this.showadd = false
      this.dialogFrom = row
      this.imageUrl = row.classTeacherPicture
      this.dialogTableVisible = true
      this.dialogFormVisible = true
    },
    saveAllocation(formName){
      this.dialogFrom.classTeacherPicture = this.imageUrl
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在提交中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$request.post('/swerAdmin/saveTeacher',this.dialogFrom).then(res =>{
            loading.close();
            this.dialogTableVisible = false
            this.dialogFormVisible = false
            this.$message.success(res.message)
            this.init()
          })
        } else {
          return false;
        }
      });
    },
    uplodeAllocation(formName){
      this.dialogFrom.classTeacherPicture = this.imageUrl
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在修改中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$request.post('/swerAdmin/uplodeTeacher',this.dialogFrom).then(res =>{
            loading.close();
            this.dialogTableVisible = false
            this.dialogFormVisible = false
            this.$message.success(res.message)
            this.init()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClick(row){
      const loading = this.$loading({
        lock: true,
        text: '正在删除中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$request.get("/swerAdmin/deleteTeacher?id=" + row.id).then(res => {
        loading.close();
        this.$message.success(res.message)
        this.init()
      })
    }
  }
}
</script>
<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>