<template>
  <div class="main">
    <div class="login dflex_c dflex_d_c">
      <div class="title">小程序后台管理系统</div>
      <el-form :model="form" :rules="rules" ref="ruleForm" class="login_form">
        <el-form-item prop="username" ref="username"><el-input v-model="form.username" placeholder="账号" @keyup.enter.native="enter"></el-input></el-form-item>
        <el-form-item prop="password" ref="password">
          <el-input v-model="form.password" placeholder="密码" show-password @keyup.enter.native="submitForm('ruleForm')"></el-input>
        </el-form-item>
        <el-button class="login_btn dflex_c margin_t_50 crpr"  :loading="loading" round size="medium" @click="submitForm('ruleForm')">登 录</el-button>
      </el-form>
    </div>
  </div>
</template>
<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Login",
  data() {
    return {
      form: {
        username: '',
        password: ''
      },
      loading: false,
      rules: {
        username: [
          {
            required: true,
            message: '账号不可以为空',
            trigger: ['blur', 'keyup']
          }
        ],
        password: [
          {
            required: true,
            message: '密码不可以为空',
            trigger: 'change'
          }
        ]
      },
    };
  },
  mounted() {
    // 当组件挂载到 DOM 后执行以下代码

    // 1. 设置焦点到用户名输入框
    this.$refs.username.$el.querySelector('input').focus();

    // 2. 从本地存储中获取上次登录的用户名，并填充到表单中
    // uni.getStorage({
    //   key: 'useadmin_last_username',
    //   success: res => {
    //     // 将获取到的用户名赋值给表单的 username 属性
    //     this.form.username = res.data;
    //     // 设置焦点到密码输入框
    //     this.$refs.password.$el.querySelector('input').focus();
    //   }
    // });
    // 3. 修改 HTML 元素的样式，设置一系列 CSS 变量的值

  },
  methods: {
    enter() {
      if (this.form.username) {
        this.$refs.password.$el.querySelector('input').focus();
      }
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.loading = true;
            this.$request.post("/login", this.form).then(res => {
              if(res.data.user.userRole === '0'){
                localStorage.setItem('token', res.data.token)
                localStorage.setItem('user',JSON.stringify(res.data.user))
                // 2. 跳转到后台主页
                this.$router.push('/indexhome')
                this.$message.success(res.message)
              }else {
                this.$message.error('请使用管理员账号登录')
              }
              // eslint-disable-next-line no-unused-vars
            }).finally(err =>{
              this.loading = false;
            })
        }
      })
    }
  }
};
</script>
<style scoped>
.main {
  width: 100%;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background-size: cover;
  padding: 0;
  margin-top: 0;
  background: linear-gradient(45deg,#9A5CE5, #F15BB5,#ed7371);
  animation: gradientBG 15s ease infinite;
  background-size: 500% 500%;
}
@keyframes gradientBG {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}

.login {
  width: 880px;
  height: 560px;
  background: #fff;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.16);
  border-radius: 20px;
}

.title {
  margin-top: 40px;
  font-size: 30px;
  font-weight: 800;
  margin-bottom: 100px;
}

::v-deep .login_form .el-input__inner {
  border: none;
  border-bottom: 1px solid #d9d9d9;
  border-radius: 0;
  padding-left: 6px;
  font-size: 14px;
  width: 260px;
  color: #ff6a6c;
}
.login_btn {
  width: 260px;
  height: 40px;
  background-color: #ff6a6c;
  border-radius: 20px;
  font-size: 18px;
  font-weight: 800;
  color: #fff;
}
</style>
