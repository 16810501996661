<template>
<div>
  <el-container>
    <el-aside width="200px"><left-window></left-window></el-aside>
    <el-container>
      <el-header><topWindow></topWindow></el-header>
      <el-main><router-view></router-view></el-main>
    </el-container>
  </el-container>
</div>
</template>

<script>
import leftWindow from "@/components/windows/leftWindow.vue";
import topWindow from "@/components/windows/topWindow.vue";

export default {
  name: "HOme",
  data(){
    return{
      user:{}
    }
  },
  components:{
    leftWindow,
    topWindow
  },
  created() {
    this.init()
  },
  methods:{
    init(){
      this.user = JSON.parse(localStorage.getItem('user'));
    }
  }
}
</script>

<style scoped>
>>>.el-main{
  background-color: #f5f5f5;
  /* position: relative; */
  top: var(--top-window-height);
  left: var(--window-left);
  margin-top: 45px;
  bottom: 0;
  right: 0;
  width: initial;
  height: 90vh;
  transition: all 0.3s ease-in-out;
  overflow-y: auto
}
</style>
