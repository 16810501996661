<template>
  <div>
    <el-aside width="180px" class="aside" ref="asideChange">
      <div class="headImg" ref="headImgChange" style="background-color: rgb(237, 116, 112);font-size: 40px;color: #FFF;text-align: center;line-height: 92px;">swer</div>
<!--      <el-image class="headImg" ref="headImgChange" src="/static/img/leftMenu.jpg" fit="contain"></el-image>-->
      <el-menu :collapse="fold" class="menu sele_none main" :default-active="onRoutes" background-color="#fff"
               text-color="#000" active-text-color="#ff6a6c" unique-opened router>
        <template v-for="item in items">
          <template v-if="item.subs && item.subs.length > 0">
            <el-submenu :index="item.url" :key="item.index">
              <template slot="title">
                <i class="icon margin_r_20" :class="item.icon"></i>
                <span slot="title">{{ item.title }}</span>
              </template>
              <template v-for="subItem in item.subs">
                <el-menu-item :index="subItem.url" :key="subItem.index">{{ subItem.title }}</el-menu-item>
              </template>
            </el-submenu>
          </template>
          <template v-else>
            <el-menu-item :index="item.url" :key="item.index">
              <i class="icon margin_r_20" :class="item.icon"></i>
              <span slot="title">{{ item.title }}</span>
            </el-menu-item>
          </template>
        </template>
      </el-menu>
    </el-aside>
  </div>
</template>

<script>
	import bus from '@/common/bus';
	export default {
    name:'leftWindow',
		data() {
			return {
				fold: false,
				items: [],
				datas: []
			};
		},
    methods: {
      getSubs(params) {
        let arr = this.datas.filter(x => x.module_parent_guid == params.guid);
        let subs = [];
        arr.forEach(data => {
          subs.push({
            index: 'sub_' + data.id,
            url: data.module_url || 'sub_' + data.id,
            title: data.module_name
          });
        });

        return subs;
      },
      getMenu() {
        const res = {
          code: 200,
          msg: '',
          datas: {
            authorize_modules: [{
              id: 59,
              guid: '758a7f6c-f033-449e-b3c8-dca323c3e1f9',
              module_parent_guid: '',
              module_name: '系统首页',
              module_sys_name: null,
              module_type: null,
              module_url: '/indexhome',
              module_icon: 'iconfont iconshouye01-01',
              module_img_url: null,
              module_level: 1,
              module_code: '10005'
            },
              {
                id: 60,
                guid: '0f15d115-c4b8-46a8-9469-b1c404aab6e6',
                module_parent_guid: '',
                module_name: '成员管理',
                module_sys_name: null,
                module_type: null,
                module_url: '',
                module_icon: 'iconfont icondianpu',
                module_img_url: null,
                module_level: 1,
                module_code: '10006'
              },
              {
                id: 61,
                guid: '88fb4c51-cc31-4abe-abb4-46c67c4476b3',
                module_parent_guid: '',
                module_name: '课程管理',
                module_sys_name: null,
                module_type: null,
                module_url: '',
                module_icon: 'iconfont iconchanpin',
                module_img_url: null,
                module_level: 1,
                module_code: '10007'
              },
              {
                id: 50,
                guid: 'f517cade-46ee-4735-9a1d-b48be2f9d1cc',
                module_parent_guid: '',
                module_name: '系统设置',
                module_sys_name: 'sys',
                module_type: '',
                module_url: '',
                module_icon: 'iconfont iconxitongshezhi',
                module_img_url: '',
                module_level: 1,
                module_code: '10065'
              },
              {
                id: 6,
                guid: 'd9105323-15dc-43a2-9165-13d0f36f4c2a',
                module_parent_guid: '0f15d115-c4b8-46a8-9469-b1c404aab6e6',
                module_name: '用户管理',
                module_sys_name: 'app 应用轮播图',
                module_type: '',
                module_url: '/addUser',
                module_icon: '',
                module_img_url: '',
                module_level: 2,
                module_code: '10006.20012'
              },
              {
                id: 15,
                guid: '5ade17a5-99d8-41fd-9ed3-0f2b832159f7',
                module_parent_guid: '88fb4c51-cc31-4abe-abb4-46c67c4476b3',
                module_name: '预约通知',
                module_sys_name: 'goods 商品',
                module_type: '',
                module_url: '/daySubscribe',
                module_icon: '',
                module_img_url: '',
                module_level: 2,
                module_code: '10007.20012'
              },
              {
                id: 4,
                guid: '6f4f903a-77c1-454e-8943-7df6b5bf6ac9',
                module_parent_guid: '0f15d115-c4b8-46a8-9469-b1c404aab6e6',
                module_name: '老师管理',
                module_sys_name: 'app 应用分类',
                module_type: '',
                module_url: '/tercherInfo',
                module_icon: '',
                module_img_url: '',
                module_level: 2,
                module_code: '10006.20012'
              },
              {
                id: 18,
                guid: '4a248661-531c-429c-a513-031375572f9e',
                module_parent_guid: '88fb4c51-cc31-4abe-abb4-46c67c4476b3',
                module_name: '添加课程',
                module_sys_name: 'goods 商品分类',
                module_type: '',
                module_url: '/addClass',
                module_icon: '',
                module_img_url: '',
                module_level: 2,
                module_code: '10007.20012'
              },
              {
                id: 55,
                guid: '4482135a-09c9-4b08-93e4-955e8c5e6314',
                module_parent_guid: 'f517cade-46ee-4735-9a1d-b48be2f9d1cc',
                module_name: '首页配置',
                module_sys_name: 'sys.user 账号',
                module_type: '',
                module_url: '/homeConfiguration',
                module_icon: '',
                module_img_url: '',
                module_level: 2,
                module_code: '10065.20008'
              },
              {
                id: 100,
                guid: '4482135a-09c9-4b08-93e4-955e8c5e63141',
                module_parent_guid: 'f517cade-46ee-4735-9a1d-b48be2f9d1cc',
                module_name: '常用图片',
                module_sys_name: '常用图片',
                module_type: '',
                module_url: '/cyimage',
                module_icon: '',
                module_img_url: '',
                module_level: 2,
                module_code: '10066.20009'
              }
            ]
          }
        };
        this.datas = res.datas.authorize_modules;
        this.datas.forEach(data => {
          if (data.module_parent_guid == '')
            this.items.push({
              icon: data.module_icon,
              url: data.module_url || data.id + '',
              index: data.id + '',
              title: data.module_name,
              subs: this.getSubs(data)
            });
        });
        localStorage.setItem('__menu',JSON.stringify(this.datas))
      }
    },
    created() {
      bus.$on('fold', res => {
        this.fold = res;
        if (res) {
          this.$refs.asideChange.$el.style.width = '64px';
          this.$refs.headImgChange.$el.style.width = '64px';
          this.$refs.headImgChange.$el.style.height = '100px';
        } else {
          this.$refs.asideChange.$el.style.width = '180px';
          this.$refs.headImgChange.$el.style.width = '100%';
          this.$refs.headImgChange.$el.style.height = '100px';
        }
      });

      this.items = [];

      if (this.items.length <= 0) {
        this.getMenu();
      }
    },
    computed: {
      onRoutes() {
        return this.$route.path;
      }
    }
	};
</script>

<style scoped>
>>>.el-menu {
  border: none;
}

>>>.el-submenu__title:hover,
>>>.el-menu-item:hover,
>>>.el-submenu__title:hover i {
  color: #ff6a6c !important;
  background-color: #fff !important;
}

>>>.el-submenu__icon-arrow {
  transform: rotate(-90deg);
  top: 31px;
  right: 20px;
}

>>>.el-submenu.is-opened>.el-submenu__title .el-submenu__icon-arrow {
  transform: rotate(0deg);
}

>>>.el-submenu .el-menu-item {
  padding: 0 0 0 40px !important;
  min-width: initial !important;
}

>>>.el-menu-item i {
  color: #909399;
}

>>>.el-menu-item.is-active:after {
  content: ' ';
  position: absolute;
  right: 0rem;
  width: 0.3125rem;
  height: 1.25rem;
  background-color: #ff6a6c;
  top: 50%;
  transform: translate(0, -50%);
}

.aside {
  background-color: #ffffff;
  overflow-x: hidden;
  overflow-y: hidden;
  position: fixed;
  left: 0;
  top: 0;
  bottom: 0;
  transition: all 0.3s ease-in-out;
}

.headImg {
  width: 100%;
  height: 100px;
  transition: all 0.3s ease-in-out;
}

.menu {
  overflow: auto;
  height: calc(100% - 100px);
  overflow-x: hidden;
}
.main{
  scrollbar-width: none;
}
.main::-webkit-scrollbar {
  display: none;
}

.icon {
  font-size: 20px;
}
>>>.el-menu-item:hover .icon, .el-submenu:hover .icon {
  font-size: 25px;
  color: #ff6a6c;
}


</style>
