// axios的封装
import axios from "axios";
import { Message } from 'element-ui';
import router from '@/router';

//本地环境
// var baseURL = 'http://localhost:88'
//测试环境
// var baseURL = 'http://39.107.253.13:88'
//正式环境
var baseURL = 'https://www.dancerswer.cn:88'
const request = axios.create({
    // 公共地址
    baseURL: baseURL,
    // 请求超时时间
    timeout: 5000
});

// axios拦截器
request.interceptors.request.use(config => {
    config.headers['Content-Type'] = 'application/json;charset=utf-8';
    let token = localStorage.getItem('token');
    if (token) {
        config.headers['Authentication'] = token;
    }
    // 请求的返回信息
    return config;
}, error => {
    // 抛出错误
    return Promise.reject(error);
});

request.interceptors.response.use(
    response => {
        let res = response.data;
        if (response.config.responseType === 'blob') {
            return res;
        }
        if (typeof res === 'string') {
            res = res ? JSON.parse(res) : res;
        }
        return res;
    },
    error => {
        if (error.response.status == 500) {
            // 如果是500错误，显示错误信息到页面
            Message.error(error.response.data.message);
        }
        if (error.response.data.code == 401) {
            router.replace('/login');
            Message.error('登录身份已过期，请重新登录');
        }
        console.log('err' + error);
        return Promise.reject(error);
    }
);

// 封装上传文件的函数
export const uploadFile = (file) => {
    const formData = new FormData();
    formData.append('file', file);
    let token = localStorage.getItem('token');
    if (token) {
        return axios.post(baseURL + '/api/manual/uploadFile', formData, {
            headers: {
                'Content-Type': 'multipart/form-data',
                'Authentication': token
            }
        });
    } else {
        // 返回一个 rejected 的 Promise
        return Promise.reject(new Error('未找到登录信息'));
    }
};


export default request;
