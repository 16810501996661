<template>
  <div>
    <div class="container padding_b_0">
      <div class="dflex_wrap">
        <div class="dflex_vertical_c margin_r_40 margin_b_20">
          <div class="search_name">用户姓名：</div>
          <el-input class="search_input" v-model="initFrom.name" placeholder="请输入" autocomplete="off"></el-input>
        </div>
        <el-button  class=" margin_b_20 margin_r_40" type="primary" icon="el-icon-search" @click="serchclass">查询</el-button>
        <el-button type="success" class=" margin_b_20 margin_r_40" icon="el-icon-circle-plus-outline" @click="addClass">添加用户</el-button>
      </div>
    </div>
    <div class="containers use-table" >
      <el-table
          v-loading="loading"
          height="60vh"
          :data="tableData"
          style="width: 100%;font-size: 14px">
        <el-table-column
            prop="username"
            label="用户名"
            width="130">
        </el-table-column>
        <el-table-column
            prop="avatar"
            label="头像"
            width="130">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.avatar"
                  :preview-src-list="srcList"
                  :z-index="scope.index"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="name"
            label="姓名"
            width="130">
        </el-table-column>
        <el-table-column
            prop="mobile"
            label="电话"
            width="150">
        </el-table-column>
        <el-table-column
            prop="ssex"
            label="性别"
            width="130">
          <template slot-scope="scope">
            {{scope.row.ssex == '0' ? '男' : '女'}}
          </template>
        </el-table-column>
        <el-table-column
            prop="userRole"
            label="用户类型"
            width="130">
          <template slot-scope="scope">
            {{scope.row.userRole == '0' ? '管理员' : '学生'}}
          </template>
        </el-table-column>
        <el-table-column
            prop="residueClassHose"
            label="剩余课时(小时)"
            width="120">
        </el-table-column>
        <el-table-column
            prop="createUser"
            label="创建人"
            width="130">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="150">
        </el-table-column>
        <el-table-column
            prop="modifyUser"
            label="修改人"
            width="130">
        </el-table-column>
        <el-table-column
            prop="modifyTime"
            label="修改时间"
            width="160">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="xg(scope.row)" style="margin-left: 10px">修改</el-button>
            <el-popconfirm
                title="删除后不可撤销，确认删除吗？"
                @confirm="handleClick(scope.row)"
                icon="el-icon-info"
                icon-color="red"
            >
              <el-button slot="reference"  type="text" size="small" style="margin-left: 20px">删除</el-button>
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="initFrom.page"
          :page-sizes="[10, 50, 100]"
          :page-size="initFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
    <!--    添加课程弹窗-->
    <div>
      <el-dialog title="添加用户" :visible.sync="dialogFormVisible" width="70%">
        <el-form :model="dialogFrom" :rules="rules" ref="dialogFrom">
          <el-row>
            <el-col :span="12">
              <el-form-item label="用户名：" label-width="100px" prop="username">
                <div style="width: 300px;">
                  <el-input v-model="dialogFrom.username" autocomplete="off"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="姓名：" label-width="100px" prop="name">
                <div style="width: 300px;">
                  <el-input v-model="dialogFrom.name" autocomplete="off"></el-input>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="性别：" label-width="100px" prop="ssex">
                <div style="width: 300px;">
                  <el-select v-model="dialogFrom.ssex" filterable placeholder="请选择性别" size="medium" style="width: 100%;">
                    <el-option label="男" value="0"></el-option>
                    <el-option label="女" value="1"></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="用户类型：" label-width="100px" prop="userRole">
                <div style="width: 300px;">
                  <el-select v-model="dialogFrom.userRole" filterable placeholder="请选择性别" size="medium" style="width: 100%;">
                    <el-option label="管理员" value="0"></el-option>
                    <el-option label="学生" value="1"></el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="剩余课时(小时)：" label-width="150px" prop="residueClassHose">
                <div style="width: 112px;">
                  <el-input-number v-model="dialogFrom.residueClassHose" :precision="1" :step="0.1"></el-input-number>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item>
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveAllocation('dialogFrom')" v-if="showadd">确 定</el-button>
            <el-button type="primary" @click="uplodeAllocation('dialogFrom')" v-if="!showadd">修 改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
export default {
  name: 'addUser',
  data() {
    return {
      initFrom: {
        page: 1,
        pageSize: 10,
        name: '',
      },
      showadd:true,
      total:null,
      tableData: [],
      srcList: [],
      loading:false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogFrom:{},
      imageUrl:'',
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: ['blur', 'change'] }],
        name: [{ required: true, message: '请输入姓名', trigger: ['blur', 'change'] }],
        ssex: [{ required: true, message: '请选择性别', trigger: ['blur', 'change']}],
        residueClassHose: [{ required: true, message: '请输入剩余课时', trigger: ['blur', 'change'] }],
        userRole: [{ required: true, message: '请选择用户类型', trigger: ['blur', 'change'] }]
      }
    }
  },
  created() {
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$request.post('/swerAdmin/getUser', this.initFrom).then(res => {
        this.tableData = res.data[0]
        this.srcList = res.data[0].map(item => item.avatar)
        this.total = parseInt(res.data[1])
        this.loading = false
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.page = val
      this.init()
    },
    serchclass(){
      this.init()
    },
    addClass(){
      this.dialogFrom = {}
      this.showadd = true
      this.dialogTableVisible = true
      this.dialogFormVisible = true
    },
    xg(row){
      this.showadd = false
      this.dialogFrom = row
      this.dialogTableVisible = true
      this.dialogFormVisible = true
    },
    saveAllocation(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在提交中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$request.post('/addUser',this.dialogFrom).then(res =>{
            loading.close();
            this.dialogTableVisible = false
            this.dialogFormVisible = false
            this.$message.success(res.message)
            this.init()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    uplodeAllocation(formName){
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
            text: '正在修改中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$request.post('/swerAdmin/uplodeUser',this.dialogFrom).then(res =>{
            loading.close();
            this.dialogTableVisible = false
            this.dialogFormVisible = false
            this.$message.success(res.message)
            this.init()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClick(row){
      const loading = this.$loading({
        lock: true,
        text: '正在删除中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$request.get("/swerAdmin/deleteUser?userIdStr=" + row.userIdStr).then(res => {
        loading.close();
        this.$message.success(res.message)
        this.init()
      })
    }
  }
}
</script>
<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>