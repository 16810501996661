<template>
  <div>
    <div class="container padding_b_0">
      <div class="dflex_wrap">
        <div class="dflex_vertical_c margin_r_40 margin_b_20">
          <div class="search_name">课程开始日期：</div>
          <el-date-picker
              class="search_input"
              v-model="initFrom.date"
              :clearable="false"
              type="date"
              placeholder="课程开始日期">
          </el-date-picker>
        </div>
        <el-button  class=" margin_b_20 margin_r_40" type="primary" icon="el-icon-search" @click="serchclass">查询</el-button>
        <el-button type="success" class=" margin_b_20 margin_r_40" icon="el-icon-circle-plus-outline" @click="addClass">添加课程</el-button>
      </div>
    </div>
    <div class="containers use-table">
      <el-table
          v-loading="loading"
          height="60vh"
          :data="tableData"
          style="width: 100%;font-size: 14px">
        <el-table-column
            prop="id"
            label="课程编号"
            width="200">
        </el-table-column>
        <el-table-column
            prop="title"
            label="标题"
            width="130">
        </el-table-column>
        <el-table-column
            prop="detailImgUrl"
            label="课程图片"
            width="130">
          <template slot-scope="scope">
            <div class="demo-image__preview">
              <el-image
                  style="width: 100px; height: 100px"
                  :src="scope.row.detailImgUrl"
                  :preview-src-list="srcList"
                  :z-index="scope.index"
              >
              </el-image>
            </div>
          </template>
        </el-table-column>
        <el-table-column
            prop="place"
            label="地点"
            width="130">
        </el-table-column>
        <el-table-column
            prop="starClassTime"
            label="开始时间"
            width="150">
        </el-table-column>
        <el-table-column
            prop="endClassTime"
            label="结束时间"
            width="150">
        </el-table-column>
        <el-table-column
            prop="classTeacherName"
            label="课程老师"
            width="100">
        </el-table-column>
        <el-table-column
            prop="classHour"
            label="课时"
            width="100">
        </el-table-column>
        <el-table-column
            prop="createUser"
            label="创建人"
            width="130">
        </el-table-column>
        <el-table-column
            prop="createTime"
            label="创建时间"
            width="150">
        </el-table-column>
        <el-table-column
            prop="updateUser"
            label="修改人"
            width="130">
        </el-table-column>
        <el-table-column
            prop="updateTime"
            label="修改时间"
            width="160">
        </el-table-column>
        <el-table-column
            fixed="right"
            label="操作"
            width="150">
          <template slot-scope="scope">
            <el-button type="text" size="small" @click="xg(scope.row)">修改</el-button>
            <el-popconfirm
                style="margin-left: 20px"
                title="删除后不可撤销，确认删除吗？"
                @confirm="handleClick(scope.row)"
                icon="el-icon-info"
                icon-color="red"
            >
<!--              <el-button slot="reference"  type="text" size="small">删除</el-button>-->
            </el-popconfirm>

          </template>
        </el-table-column>
      </el-table>
      <el-pagination
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          :current-page="initFrom.page"
          :page-sizes="[10, 50, 100]"
          :page-size="initFrom.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="total">
      </el-pagination>
    </div>
<!--    添加课程弹窗-->
    <div>
      <el-dialog title="添加课程" :visible.sync="dialogFormVisible" width="70%">
        <el-form :model="dialogFrom" :rules="rules" ref="dialogFrom">
          <el-row>
            <el-col :span="12">
              <el-form-item label="课程标题：" label-width="100px" prop="title">
                <div style="width: 300px;">
                  <el-input v-model="dialogFrom.title" autocomplete="off"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程老师：" label-width="150px" prop="classTeacherId">
                <div style="width: 300px;">
                  <el-select v-model="dialogFrom.classTeacherId" filterable placeholder="请选择上课老师" size="medium" style="width: 100%;">
                    <el-option
                        v-for="item in teacherList"
                        :key="item.id"
                        :label="item.classTeacherName"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="地点：" label-width="100px" prop="place">
                <div style="width: 300px;">
                  <el-input v-model="dialogFrom.place" autocomplete="off"></el-input>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程开始时间：" label-width="150px" prop="starClassTime">
                <div style="width: 230px;">
                  <el-date-picker
                      v-model="dialogFrom.starClassTime"
                      type="datetime"
                      :clearable="false"
                      placeholder="选择日期时间"
                      >
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="课时：" label-width="100px" prop="classHour">
                <div style="width: 112px;">
                  <el-input-number v-model="dialogFrom.classHour" :precision="1" :step="0.1" :max="10" :min="0.5" @change="jssj"></el-input-number>
                </div>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="课程结束时间：" label-width="150px">
                <div style="width: 230px;">
                  <el-date-picker
                      :readonly="true"
                      :disabled="true"
                      v-model="dialogFrom.endClassTime"
                      type="datetime"
                      placeholder="结束时间">
                  </el-date-picker>
                </div>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="课程安排：" label-width="100px" prop="detail">
            <el-input
                type="textarea"
                :rows="2"
                placeholder="请输入内容"
                v-model="dialogFrom.detail"
            >
            </el-input>
          </el-form-item>
          <el-form-item label="常用图片：" label-width="100px">
            <div style="width: 300px;">
              <el-select v-model="cyurl" filterable placeholder="常用图片" @change="cytp" size="medium" style="width: 100%;">
                <el-option
                    v-for="item in cyimageList"
                    :key="item.id"
                    :label="item.imageName"
                    :value="item.imageUrl"
                    >
                </el-option>
              </el-select>
            </div>
          </el-form-item>
          <el-form-item label="课程图片：" label-width="100px" prop="detailImgUrl">
            <div style="width:179px">
              <el-upload
                  class="avatar-uploader"
                  action
                  :http-request="uploadFiles"
                  :show-file-list="false">
                <img v-if="imageUrl" :src="imageUrl" class="avatar">
                <i v-else class="el-icon-plus avatar-uploader-icon"></i>
              </el-upload>
            </div>
          </el-form-item>
          <el-form-item>
            <el-button @click="dialogFormVisible = false">取 消</el-button>
            <el-button type="primary" @click="saveAllocation('dialogFrom')" v-if="showadd">确 定</el-button>
            <el-button type="primary" @click="uplodeAllocation('dialogFrom')" v-if="!showadd">修 改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import { uploadFile } from '@/utils/request';
export default {
  name: 'addClass',
  data() {
    return {
      initFrom: {
        page: 1,
        pageSize: 10,
        date: null,
      },
      showadd:true,
      teacherList:[],
      total:null,
      tableData: [],
      cyimageList:[],
      srcList: [],
      loading:false,
      dialogTableVisible: false,
      dialogFormVisible: false,
      dialogFrom:{},
      imageUrl:'',
      classTeacherUserId:'',
      cyurl:'',
      rules: {
        title: [{ required: true, message: '请输入课程标题', trigger: ['blur', 'change'] }],
        classTeacherId: [{ required: true, message: '请选择课程老师', trigger: ['blur', 'change'] }],
        place: [{ required: true, message: '请输入地点', trigger: ['blur', 'change']}],
        starClassTime: [{ required: true, message: '请输入课程开始时间', trigger: ['blur', 'change'] }],
        classHour: [{ required: true, message: '请输入课时', trigger: ['blur', 'change'] }],
        detailImgUrl: [{ required: true, message: '请上传课程图片', trigger: ['blur', 'change'] }],
        detail: [{ required: true, message: '请输入课程安排', trigger: ['blur', 'change'] }],
      }
    }
  },
  created() {
    this.initFrom.date = this.$moment().format('YYYY-MM-DD')
    this.init()
  },
  methods: {
    init() {
      this.loading = true
      this.$request.post('/swerAdmin/getClass', this.initFrom).then(res => {
        this.tableData = res.data[0]
        console.log(this.tableData)
        this.srcList = res.data[0].map(item => item.detailImgUrl)
        this.total = parseInt(res.data[1])
        this.loading = false
      })
      this.$request.get('/swerAdmin/getTercher').then(res =>{
        this.teacherList = res.data
      })
      this.$request.post('/swerAdmin/getimageList', {"page":1,"pageSize":10000}).then(res => {
        this.cyimageList = res.data[0]
      })
    },
    handleSizeChange(val) {
      this.pageSize = val
      this.init()
    },
    handleCurrentChange(val) {
      this.page = val
      this.init()
    },
    serchclass(){
      this.initFrom.date = this.$moment(this.initFrom.date).format('YYYY-MM-DD')
      this.init()
    },
    addClass(){
      this.dialogFrom = {}
      this.dialogFrom.detail="预约后开课24小时不可取消，自动扣除课时，请各位注意⚠️！"
      this.dialogFrom.place="8-я линия Васильевского острова, 83к1"
      this.imageUrl = ''
      this.showadd = true
      this.dialogTableVisible = true
      this.dialogFormVisible = true
    },
    uploadFiles(param){
      const isJPG = param.file.type === 'image/jpeg';
      const isLt2M = param.file.size / 1024 / 1024 < 5;
      if (!isJPG) {
        this.$message.error('上传头像图片只能是 JPG 格式!');
        return
      }
      if (!isLt2M) {
        this.$message.error('上传头像图片大小不能超过 5MB!');
        return;
      }
      const loading = this.$loading({
        lock: true,
        text: '正在上传中',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      uploadFile(param.file).then(res => {
        this.imageUrl = res.data.data
        this.$message.success('上传成功')
        loading.close();
      })
    },
    jssj(currentValue){
      if (this.dialogFrom.starClassTime){
        // 假设 picker 是一个时间字符串或 Date 对象
        const pickerMoment = this.$moment(this.dialogFrom.starClassTime);
        // 将时间加上两小时
        const newDateTime = pickerMoment.add(currentValue, 'hours');
        // 格式化新时间
        this.dialogFrom.endClassTime = newDateTime;
      }else {
        this.$message.error("请先输入课程开始时间！")
      }
    },
    xg(row){
      this.showadd = false
      this.dialogFrom = row
      this.imageUrl = row.detailImgUrl
      this.dialogTableVisible = true
      this.dialogFormVisible = true
      console.log(row)
    },
    saveAllocation(formName){
      this.dialogFrom.detailImgUrl = this.imageUrl
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogFrom.starClassTime = this.$moment(this.dialogFrom.starClassTime).format('YYYY-MM-DD HH:mm:ss')
          this.dialogFrom.endClassTime = this.$moment(this.dialogFrom.endClassTime).format('YYYY-MM-DD HH:mm:ss')
          const loading = this.$loading({
            lock: true,
            text: '正在提交中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$request.post('/swerAdmin/saveClass',this.dialogFrom).then(res =>{
            loading.close();
            this.dialogTableVisible = false
            this.dialogFormVisible = false
            this.$message.success(res.message)
            this.init()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    uplodeAllocation(formName){
      this.dialogFrom.detailImgUrl = this.imageUrl
      this.$refs[formName].validate((valid) => {
        if (valid) {
          this.dialogFrom.starClassTime = this.$moment(this.dialogFrom.starClassTime).format('YYYY-MM-DD HH:mm:ss')
          this.dialogFrom.endClassTime = this.$moment(this.dialogFrom.endClassTime).format('YYYY-MM-DD HH:mm:ss')
          const loading = this.$loading({
            lock: true,
            text: '正在修改中...',
            spinner: 'el-icon-loading',
            background: 'rgba(0, 0, 0, 0.7)',
          });
          this.$request.post('/swerAdmin/uplodeClass',this.dialogFrom).then(res =>{
            loading.close();
            this.dialogTableVisible = false
            this.dialogFormVisible = false
            this.$message.success(res.message)
            this.init()
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    handleClick(row){
      const loading = this.$loading({
        lock: true,
        text: '正在删除中...',
        spinner: 'el-icon-loading',
        background: 'rgba(0, 0, 0, 0.7)',
      });
      this.$request.get("/swerAdmin/deleteClass?id=" + row.id).then(res => {
        loading.close();
        this.$message.success(res.message)
        this.init()
      })
    },
    cytp(val){
      this.imageUrl = val
    }
  }
}
</script>
<style scoped>
::v-deep .avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>